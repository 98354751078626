import { useState } from 'react'
import arrowBack from '../../assets/images/svgs/arrow-back.svg'
import greenpoints from '../../assets/images/svgs/green-points.svg'
import info from '../../assets/images/svgs/info-white.svg'
import minus from '../../assets/images/svgs/minus.svg'
import plus from '../../assets/images/svgs/plus.svg'
import whitepoints from '../../assets/images/svgs/white-points.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { Button } from '../../components/button'
import SponsorInfo from '../../components/sponsor-info'
import { donateProject } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { User } from '../../types/api'
import './styles.scss'

const SponsorScreen = () => {
    const { activeProject } = useAppSelector((state) => state.impact)
    const access_token = useAppSelector((state) => state.auth.access_token)
    const user = useAppSelector((state) => state.auth.user) as User

    const dispatch = useAppDispatch()

    const [points, setPoints] = useState(activeProject.unit_points)
    const [unit_amount, setUnit_amount] = useState(activeProject.unit_amount)
    const [showInfo, setShowInfo] = useState(false)

    const _plusPoints = () => {
        setPoints(points + activeProject.unit_points)
        setUnit_amount(unit_amount + activeProject.unit_amount)
    }

    const _minusPoints = () => {
        if (points - activeProject.unit_points > 0) {
            setPoints(points - activeProject.unit_points)
            setUnit_amount(unit_amount - activeProject.unit_amount)
        }
    }

    const _goBack = () => {
        dispatch(changeScreen('Project Details'))
    }
    const _confirmDonate = () => {
        donateProject(
            {
                amount: points,
            },
            access_token.access_token,
            activeProject.id
        )
    }

    const _showSponsorInfo = () => {
        setShowInfo(true)
    }

    const _hideSponsorInfo = () => {
        setShowInfo(false)
    }

    return (
        <div className="sponsor-container">
            <div className="sponsor-container__hero-wrapper">
                <div className="sponsor-container__top-wrapper">
                    <div
                        className="sponsor-container__back-btn"
                        onClick={_goBack}
                    >
                        <img src={arrowBack} />
                    </div>
                    <div
                        className="sponsor-container__info-btn"
                        onClick={_showSponsorInfo}
                    >
                        <img src={info} />
                    </div>
                </div>
                <div className="sponsor-container__points-wrapper">
                    <h3 className="sponsor-container_balance-title">
                        Available Balance
                    </h3>
                    <p className="sponsor-container_points">
                        <p className="sponsor_points">
                            {user.loyalty_account.amount.amount}
                        </p>
                        <img src={whitepoints} />
                        <p>points</p>
                    </p>
                </div>
            </div>
            <div className="sponsor-container__content">
                <h3 className="sponsor-container_content-title">
                    Have a positive impact and help balance the world a little
                </h3>

                <div className="sponsor-container__donate-container">
                    <div className="sponsor-container_number">
                        <h3 className="sponsor-container_number-title">
                            {unit_amount}{' '}
                        </h3>
                        <span className="sponsor-container_number-small">
                            {activeProject.unit}
                        </span>
                    </div>
                    <p className="sponsor-container_rewilded">
                        {activeProject.unit_description}
                    </p>
                    <div className="sponsor-container__points-choose-wrapper">
                        <button
                            className="sponsor-container_add-remove-button"
                            onClick={_minusPoints}
                        >
                            <img src={minus} />
                        </button>
                        <div className="sponsor-container__chosen-points">
                            <p className="sponsor-container_choosen-points-number">
                                {points}
                            </p>
                            <img src={greenpoints} />
                        </div>
                        <button
                            className="sponsor-container_add-remove-button"
                            onClick={_plusPoints}
                        >
                            <img src={plus} />
                        </button>
                    </div>
                </div>
                <div className="sponsor-container__bottom-wrapper">
                    <Button
                        variant="black"
                        text="Confirm"
                        onClick={_confirmDonate}
                        disabled={points > user.loyalty_account.amount.amount}
                    />
                </div>
            </div>
            {showInfo && <SponsorInfo hideInfo={_hideSponsorInfo} />}
        </div>
    )
}

export default SponsorScreen
