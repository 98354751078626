import React from 'react'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setActiveProject } from '../../store/impact/impact-action'
import { setActiveProjectAsync } from '../../store/impact/impact-asyncActions'
import { Project } from '../../types/api'

import './styles.scss'

interface Props {
    item: Project
}

export const ProjectCard: React.FC<Props> = ({ item }) => {
    const dispatch = useAppDispatch()
    const { access_token } = useAppSelector(store => store.auth.access_token)

    const _goToDetails = () => {
        dispatch(setActiveProject(item))
        dispatch(setActiveProjectAsync(access_token, item.id) as any)
        dispatch(changeScreen('Project Details'))
    }
    return (
        <div
            className="project-card-container"
            style={item.background_image ? { backgroundImage: `url(${item.background_image.url})` } : {
                backgroundColor: '#004236'
            }}
            onClick={_goToDetails}
        >
            <div className="project-card-container__content">
                <h3 className="project-card-container_name">
                    {item.name.toLowerCase()}
                </h3>
            </div>
        </div>
    )
}
