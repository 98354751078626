import React from 'react'

import './styles.scss'
interface Props {
    text: string
    icon: string
    onClick?: () => void
    disabled?: boolean
}

const SocialButton: React.FC<Props> = ({ text, icon, onClick, disabled }) => {
    return (
        <button className="social-button" onClick={onClick} disabled={disabled}>
            <img src={icon} className="social-button_image" />
            <p className="social-button_title">{text}</p>
        </button>
    )
}

export default SocialButton
