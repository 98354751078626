import { useGoogleLogin } from '@react-oauth/google'
import AppleSignin from 'react-apple-signin-auth'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/LOGO.png'
import apple from '../../assets/images/svgs/apple.svg'
import close from '../../assets/images/svgs/close.svg'
import facebook from '../../assets/images/svgs/facebook.svg'
import google from '../../assets/images/svgs/google.svg'
import { Button } from '../../components/button'
import SocialButton from '../../components/social-button'
import {
    getTokenWithApple,
    getTokenWithFacebook,
    getTokenWithGoogle,
} from '../../services/api'
import {
    APPLE_REDIRECT_URL,
    CLIENT_ID,
    CLIENT_SECRET,
} from '../../services/config'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import './styles.scss'

const StartScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const _closeModal = () => {
        document.body.style.overflow = 'visible'
        navigate('/')
    }
    const _signUp = () => {
        dispatch(changeScreen('Sign Up'))
    }

    const _signIn = () => {
        dispatch(changeScreen('Sign In'))
    }

    const _responseGoogle = async (response: any) => {
        if (response.access_token) {
            await getTokenWithGoogle({
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                grant_type: 'https://google.com',
                scope: 'ios',
                access_token: response.access_token,
            })
        }
    }

    const _dataGoogle = (response: any) => {
        console.log(response, 'FAILED GOOGLE')
    }

    const _responseFacebook = async (response: any) => {
        if (response.accessToken) {
            await getTokenWithFacebook({
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                grant_type: 'https://facebook.com',
                scope: 'ios',
                facebook_app_user_access_token: response.accessToken,
            })
        }
    }

    const _responseApple = async (response: any) => {
        if (response.authorization) {
            if (response.user) {
                await getTokenWithApple({
                    client_id: CLIENT_ID,
                    client_secret: CLIENT_SECRET,
                    grant_type: 'https://apple.com',
                    scope: 'ios',
                    first_name: response.user.name.firstName,
                    last_name: response.user.name.lastName,
                    identity_token: response.authorization.id_token,
                })
            } else {
                await getTokenWithApple({
                    client_id: CLIENT_ID,
                    client_secret: CLIENT_SECRET,
                    grant_type: 'https://apple.com',
                    scope: 'ios',
                    identity_token: response.authorization.id_token,
                })
            }
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: _responseGoogle,
        onError: _dataGoogle,
    })

    return (
        <div className="container">
            <div className="container__close-wrapper">
                <img
                    className="container-close"
                    onClick={_closeModal}
                    src={close}
                />
            </div>
            <div className="container__body">
                <div className="container__logo-container">
                    <img src={logo} className="container_logo" />
                </div>
                <h3 className="container_title">Sign in with</h3>
                <div className="container__social-buttons-container">
                    <FacebookLogin
                        appId="278242336972256"
                        fields="name,email,picture"
                        onClick={() => ({})}
                        isMobile={false}
                        callback={_responseFacebook}
                        render={(renderProps) => (
                            <SocialButton
                                onClick={renderProps.onClick}
                                disabled={renderProps.isDisabled}
                                text="Facebook"
                                icon={facebook}
                            />
                        )}
                    />
                    <SocialButton
                        onClick={googleLogin}
                        text="Google"
                        icon={google}
                    />
                    <AppleSignin
                        authOptions={{
                            clientId: 'com.protonpartners.ample.webapp',
                            scope: 'email name',
                            redirectURI: APPLE_REDIRECT_URL,
                            state: 'state',
                            nonce: 'nonce',
                            usePopup: true,
                        }}
                        onSuccess={_responseApple}
                        onError={({ error }: any) => {
                            console.log(error, 'ERRORRR')
                        }}
                        render={(props: any) => (
                            <SocialButton
                                text="Sign in with Apple"
                                icon={apple}
                                onClick={props.onClick}
                            />
                        )}
                    />
                </div>
                <div className="container__or-container">
                    <div className="container__or-line"></div>
                    <div>
                        <p className="container_or-text">OR</p>
                    </div>
                    <div className="container__or-line"></div>
                </div>
                <div className="container__sign-up-wrapper">
                    <Button
                        variant="black-outlined"
                        text="Sign Up"
                        onClick={_signUp}
                    />
                </div>
            </div>
            <p className="container_already-text">
                Already have an account?{' '}
                <span onClick={_signIn} className="container_sign-in">
                    Sign in
                </span>
            </p>
        </div>
    )
}

export default StartScreen
