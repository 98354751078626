import first_slider from '../assets/images/first-slider.png'
import fourth_slider from '../assets/images/fourth-slider.png'
import second_slider from '../assets/images/second-slider.png'
import sliderItem1 from '../assets/images/sliderItem1.png'
import sliderItem3 from '../assets/images/sliderItem3.png'
import sliderItem4 from '../assets/images/sliderItem4.png'
import logoSlider1 from '../assets/images/svgs/logoSlider1.svg'
import logoSlider2 from '../assets/images/svgs/logoSlider2.svg'
import logoSlider3 from '../assets/images/svgs/logoSlider3.svg'
import peopleLogo from '../assets/images/svgs/people.svg'
import planetLogo from '../assets/images/svgs/planet.svg'
import principlesLogo from '../assets/images/svgs/principles.svg'
import third_slider from '../assets/images/third-slider.png'

export const sliders = [
    {
        image: sliderItem1,
        text: 'Search brands and see their sustainability',
    },
    {
        image: sliderItem4,
        text: 'Learn about their journey and shop with impact',
    },
    {
        image: sliderItem3,
        text: 'Rebalance your basket with Ample Points',
    },
]

export const detailsSlider = [
    {
        img: first_slider,
        title: {
            title: 'Planet',
            subtitle: 'Commitment to Environmental Responsibility',
            img: planetLogo,
        },
        text: {
            title: 'Performance measures',
            text: 'Durable Products \n Sustainable Sourcing \n Natural Fibers \n Low Chemicals Use \n Water Reduction Initiatives \n Energy Management & Renewables \n Reducing Carbon Emissions \n Recycling & Waste Management \n Working to Eliminate Plastics \n Support Biodiversity & Animal \n Welfare \n Circular Economy Participation \n Minimal Packaging',
        },
    },
    {
        img: second_slider,
        title: {
            title: 'People',
            subtitle: 'Commitment to an Equitable and Just Society',
            img: peopleLogo,
        },
        text: {
            title: 'Performance measures',
            text: 'Fair Pay & Compensation \n Diverse & Inclusive Workforce \n Socially Progressive Recruitment \n Education & Professional Development \n Preserve Heritage & Craft \n Protect Labor Rights & Freedoms \n Health & Safety \n Responsible Leadership \n Worker Ownership or Profit Sharing \n Community Investments \n Supports Charities or Social Enterprises',
        },
    },
    {
        img: third_slider,
        title: {
            title: 'Principles',
            subtitle: 'Commitment to sustainble Business Practices',
            img: principlesLogo,
        },
        text: {
            title: 'Performance measures',
            text: 'Transparency & Reporting\nTraceability & Accountability \nPolicies & Codes of Conduct\nPledges & Commitments Alliances \n Memberships & Partnerships Green \n Finance & Taxes \nResponsible Pensions & Investments \nCompany Culture & Leadership\nUse of Technology for Good',
        },
    },
    {
        img: fourth_slider,
        title: {
            title: 'Ratings',
        },
        logos: {
            logo1: logoSlider1,
            logo2: logoSlider2,
            logo3: logoSlider3,
        },
        p1: {
            title: 'GREEN',
            text: `Environmentally & Socially Responsible 
      Businesses. 
      Ample can verify the brand’s sustainable 
      actions and activities.`,
        },
        p2: {
            title: 'AMBER',
            text: `The company is takings steps in the right 
      direction and Ample will monitor their 
      progress.`,
        },
        p3: {
            title: 'GREY',
            text: `There is insufficient data. This may be due 
      to inaction or the brand not sharing 
      sustainability information.`,
        },
    },
]

export type interestsType = {
    name: string
    isSelected: boolean
}
export const interests: Array<interestsType> = [
    { name: 'Made in UK', isSelected: false },
    { name: 'Climate Action', isSelected: false },
    { name: 'No Waste', isSelected: false },
    { name: 'Diversity+ Inclusivity', isSelected: false },
    { name: 'Small Business', isSelected: false },
    { name: 'Economic Empowerment', isSelected: false },
    { name: 'Ethical Labour', isSelected: false },
    { name: 'Artisanal', isSelected: false },
    { name: 'Support Communyties', isSelected: false },
    { name: 'Nature & Biodiversity', isSelected: false },
    { name: 'Pland-Based Product', isSelected: false },
    { name: 'Reducing Water Use', isSelected: false },
    { name: 'Fair Tax & Green Finance', isSelected: false },
    { name: 'Rent, Repair & Resell', isSelected: false },
    { name: 'Sustainable Sourcing', isSelected: false },
    { name: 'Transparency+ Traceability', isSelected: false },
]
