import { AppDispatch } from ".."
import { getProjectById } from "../../services/api"
import { Project } from "../../types/api"
import { setActiveProject } from "./impact-action"

export function setActiveProjectAsync(access_token: string, id: string) {
  return async function(dispatch: AppDispatch) {
    const project: Project = await getProjectById(access_token, id)

    dispatch(setActiveProject(project))
  }
}