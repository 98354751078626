import React from 'react'
import { Button } from '../button'
import './styles.scss'

interface Props {
    hideInfo: () => void
}

const SponsorInfo: React.FC<Props> = ({ hideInfo }) => {
    return (
        <div className="sponsor-info-container">
            <div className="sponsor-info-container__modal">
                <h3 className="sponsor-info-container_title">
                    Available balance
                </h3>
                <p className="sponsor-info-container_subtitle">
                    Not all points can be immediately spent.
                </p>
                <p className="sponsor-info-container_desc">
                    It can take up to 90 days before transactions are cleared as
                    items may be returned.
                </p>
                <p className="sponsor-info-container_desc">
                    Once points are cleared, they will appear on your available
                    balance and can be used to fund impact projects.
                </p>
                <br />
                <Button variant="black" text="OK" onClick={hideInfo} />
            </div>
        </div>
    )
}

export default SponsorInfo
