import points from '../../assets/images/svgs/points.svg'
import { Button } from '../../components/button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import './styles.scss'

const WelcomeSignupScreen = () => {
    const dispatch = useAppDispatch()

    const _start = () => {
        dispatch(changeScreen('Select Interest'))
    }
    return (
        <div className="welcome-container">
            <div className="welcome-container__wrapper">
                <h1 className="welcome-container_title">WELCOME</h1>
                <p className="welcome-container_desc">
                    A gift for you to pay it forward.
                </p>
                <div className="welcome-container__points-wrapper">
                    <p className="welcome-container_points-number">100</p>
                    <img src={points} />
                    <p className="welcome-container_points-text">points</p>
                </div>
                <p className="welcome-container_bottom-desc">
                    Rewild 2.5 sqm of land with Ample today.
                </p>
            </div>
            <div className="welcome-container__bottom-wrap">
                <Button text="START" onClick={_start} variant="black" />
            </div>
        </div>
    )
}

export default WelcomeSignupScreen
