import React from 'react'
import first from '../../assets/images/first.png'
import second from '../../assets/images/second.png'
import trirty from '../../assets/images/trirty.png'
import { CorporatePartner } from '../../types/api'
import './styles.scss'
interface Props {
    data: CorporatePartner
    className: string
    onClick?: (e: number) => void
}
const Photo_Buttons: React.FC<Props> = ({ data, className, onClick }) => {
    const _renderColor = (number: number | undefined) => {
        switch (number) {
            case null:
                return '#E4E0E1'
            case 0:
                return '#E4E0E1'
            case 1:
                return '#E4E0E1'
            case 2:
                return '#D3A04C'
            case 3:
                return '#D3A04C'
            case 4:
                return '#D3A04C'
            case 5:
                return '#3E6331'
            default:
                return
        }
    }
    return (
        <div className={className}>
            <div
                onClick={() => {
                    onClick && onClick(0)
                }}
                className="photo-button__image-back"
            >
                <img
                    src={first}
                    className={`${className}_icon-item`}
                    style={{
                        borderColor: _renderColor(
                            data?.esg_score?.environmental
                        ),
                    }}
                />
            </div>
            <div
                onClick={() => {
                    onClick && onClick(1)
                }}
                className={`${className}__image-back`}
            >
                <img
                    src={second}
                    className={`${className}_icon-item`}
                    style={{
                        borderColor: _renderColor(data?.esg_score?.social),
                    }}
                />
            </div>
            <div
                onClick={() => {
                    onClick && onClick(2)
                }}
                className={`${className}__image-back`}
            >
                <img
                    src={trirty}
                    className={`${className}_icon-item`}
                    style={{
                        borderColor: _renderColor(data?.esg_score?.governance),
                    }}
                />
            </div>
        </div>
    )
}

export default Photo_Buttons
