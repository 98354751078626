import { useEffect, useState } from 'react'

import { Button } from '../../components/button'
import { InterestButton } from '../../components/interest-button'
import SettingsHeader from '../../components/settings-header'
import { editOwnProfile, getInterests } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Interest, User } from '../../types/api'

import './styles.scss'

const SelectInterest = () => {
    const user = useAppSelector((state) => state.auth.user) as User
    const [selectedInterests, setSelectedInterests] = useState<Array<string>>(
        user.user_interests.map((e: Interest) => {
            return e.id
        })
    )
    const [Interests, setInterests] = useState<Array<Interest>>([])
    const { access_token } = useAppSelector((state) => state.auth)
    const GetInterests = async () => {
        const data = await getInterests(access_token.access_token)
        setInterests(data)
    }
    useEffect(() => {
        GetInterests()
    }, [])
    const dispatch = useAppDispatch()
    const _addSelected = (item: Interest) => {
        setSelectedInterests([...selectedInterests, item.id])
    }
    const _removeSelected = (item: Interest) => {
        const newSelectedInterests = selectedInterests.filter(
            (e: any) => e !== item.id
        )
        setSelectedInterests(newSelectedInterests)
    }
    const _save = () => {
        const body = {
            user_interests: [...selectedInterests],
            edit_version: user.edit_version,
        }
        editOwnProfile(body, access_token)
        dispatch(changeScreen('Menu Screen'))
    }
    return (
        <>
            <SettingsHeader screen="Menu Screen" text="SELECT INTERESTS" />
            <div className="select-container">
                <div className="select-container__content">
                    <div className="select-container__interests-wrapper">
                        {Interests.map((item: Interest, index: number) => (
                            <InterestButton
                                item={item}
                                selectedInterests={selectedInterests}
                                key={index}
                                addSelected={_addSelected}
                                removeSelected={_removeSelected}
                            />
                        ))}
                    </div>
                    <div className="select-container__bottom-wrapper">
                        <Button
                            variant="black"
                            text="Save"
                            onClick={_save}
                            disabled={
                                selectedInterests.length > 0 ? false : true
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectInterest
