import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import bg from '../../assets/images/menu-bg.png'
import ArrowBack_Icon from '../../assets/images/svgs/arrow-back-white.svg'
import FollowingIcon from '../../assets/images/svgs/following.svg'
import MyImpactIcon from '../../assets/images/svgs/myImpact.svg'
import points from '../../assets/images/svgs/points.svg'
import Select_InterestsIcon from '../../assets/images/svgs/select-interests.svg'
import SettingsIcon from '../../assets/images/svgs/settings.svg'
import TransactionsIcon from '../../assets/images/svgs/transactions.svg'
import User_feedbackIcon from '../../assets/images/svgs/user-feedback.svg'
import { Menu_buttons } from '../../components/menu_button/menu_button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { User } from '../../types/api'
import './styles.scss'

const MenuScreen = () => {
    const user = useAppSelector((state) => state.auth.user) as User
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        if (params.brandName) {
            dispatch(changeScreen('Corporate Details'))
        }
    })

    const _closeModal = () => {
        document.body.style.overflow = 'visible'
        navigate('/')
    }

    const _nextScreen = (screen: string) => {
        dispatch(changeScreen(screen))
    }
    return (
        <div className="menu">
            <div className="menu_profile-bg">
                <img className="menu_profile-bg_photo" src={bg} />
            </div>
            <div className="menu__backarrow" onClick={_closeModal}>
                <img src={ArrowBack_Icon} />
            </div>
            <div className="menu__porfile-info">
                <img
                    className="menu__porfile-info_image"
                    src={user?.profile_photo?.url}
                />
                <div className="menu__porfile-info_name-points">
                    <div className="menu__porfile-info_name-points__name">
                        {user.first_name}
                    </div>
                    <div className="menu__porfile-info_name-points__border"></div>
                    <div className="menu__porfile-info_name-points__points">
                        <div
                            className="menu__porfile-info_name-points__points-text"
                            style={{ marginRight: 5, fontWeight: 400 }}
                        >
                            {user.loyalty_account.amount.amount}
                        </div>
                        <div style={{ marginTop: -1 }}>
                            <img src={points} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu__empty"></div>
            <div className="menu__buttons-container">
                <Menu_buttons
                    text="My Impact"
                    icon={MyImpactIcon}
                    onClick={() => {
                        _nextScreen('My Impact')
                    }}
                />
                <Menu_buttons
                    text="Transactions"
                    icon={TransactionsIcon}
                    onClick={() => {
                        _nextScreen('Transaction Screen')
                    }}
                />
                <Menu_buttons
                    text="Following"
                    icon={FollowingIcon}
                    onClick={() => {
                        _nextScreen('Following')
                    }}
                />
                <Menu_buttons
                    text="Select Interests"
                    icon={Select_InterestsIcon}
                    onClick={() => {
                        _nextScreen('Select Interest')
                    }}
                />
                <Menu_buttons
                    text="User feedback"
                    icon={User_feedbackIcon}
                    onClick={() => {
                        window.location.href = 'mailto:hello@ample.earth'
                    }}
                />
                <Menu_buttons
                    text="Settings"
                    icon={SettingsIcon}
                    onClick={() => {
                        _nextScreen('Settings')
                    }}
                />
            </div>
        </div>
    )
}

export default MenuScreen
