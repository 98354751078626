import { useState } from 'react'
import arrowNext_Icon from '../../assets/images/svgs/arrow-next.svg'
import Delete_Icon from '../../assets/images/svgs/delete-account.svg'
import EditProfile_Icon from '../../assets/images/svgs/edit-profile.svg'
import Logout_Icon from '../../assets/images/svgs/logout.svg'
import TermsOfUse_Icon from '../../assets/images/svgs/terms-of-use.svg'
import { SettingsButton } from '../../components/settings-button'
import SettingsHeader from '../../components/settings-header'
import { changeScreen, logOut } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { DeleteAccount } from '../delete-account'
import './styles.scss'

const Settings = () => {
    const [deleteAccount, setDeleteAccount] = useState(false)
    const dispatch = useAppDispatch()

    const _navigationHandler = (screen: string) =>
        dispatch(changeScreen(screen))

    const _logout = () => {
        dispatch(logOut())
        _navigationHandler('Start screen')
    }
    const _delete_account = () => {
        setDeleteAccount(true)
    }
    return (
        <div className="settings-container">
            <SettingsHeader screen="Menu Screen" text="SETTINGS" />
            <div className="settings-container__buttons">
                <SettingsButton
                    text="Edit profile"
                    icon={EditProfile_Icon}
                    arrow={arrowNext_Icon}
                    onClick={() => _navigationHandler('Edit Profile')}
                />
                <SettingsButton
                    text="Terms of use"
                    icon={TermsOfUse_Icon}
                    arrow={arrowNext_Icon}
                    onClick={() => _navigationHandler('Terms Of Use')}
                />
                <SettingsButton
                    text="Logout"
                    icon={Logout_Icon}
                    onClick={_logout}
                />
                <SettingsButton
                    text="Delete account"
                    icon={Delete_Icon}
                    onClick={_delete_account}
                />
            </div>
            {deleteAccount && (
                <DeleteAccount setDeleteAccount={setDeleteAccount} />
            )}
        </div>
    )
}

export default Settings
