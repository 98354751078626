import React from 'react'
import ArrowBack_Icon from '../../assets/images/svgs/arrow-black.svg'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import './styles.scss'

interface Props {
    screen: string
    text: string
}
const SettingsHeader: React.FC<Props> = ({ screen, text }) => {
    const dispatch = useAppDispatch()
    const _navigationHandler = (screen: string) =>
        dispatch(changeScreen(screen))
    return (
        <>
            <div className="container__header">
                <div
                    className="container__backarrow"
                    onClick={() => _navigationHandler(screen)}
                >
                    <img src={ArrowBack_Icon} />
                </div>
                <div className="container__text-container">
                    <div className="container__text">{text}</div>
                </div>
            </div>
            <div className="container__border"></div>
        </>
    )
}
export default SettingsHeader
