import { useEffect, useState } from 'react'
import './styles.scss'

import close from '../../assets/images/svgs/close.svg'
import { Button } from '../../components/button'
import { PasswordField } from '../../components/password-field'
import TextField from '../../components/text-field'
import { getAccessTokenSignUp, signUp } from '../../services/api'
import { CLIENT_ID, CLIENT_SECRET } from '../../services/config'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { validateEmail } from '../../utils/_validateEmail'

const SignUp = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const dispatch = useAppDispatch()
    const [error, setError] = useState('')
    const { access_token } = useAppSelector((state) => state.auth)

    useEffect(() => {
        getAccessTokenSignUp({
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'client_credentials',
            scope: 'ios',
        })
    }, [])

    const _closeSignUp = () => {
        dispatch(changeScreen('Start screen'))
    }
    const _setvalue = (SetState: (e: string) => void, e: string) => {
        SetState(e)
        setError('')
    }

    const _signUp = () => {
        if (
            !email.trim() ||
            !password.trim() ||
            !confirmPassword.trim() ||
            !firstName.trim() ||
            !lastName.trim()
        ) {
            setError('Please fill all fields')
        } else if (!validateEmail(email)) {
            setError('Incorrect email')
        } else {
            signUp(
                {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    password: password,
                    password_confirmation: confirmPassword,
                },
                access_token.access_token,
                setError
            )
        }
    }
    return (
        <div className="sign-up-container">
            <div
                className="sign-up-container__close-wrapper"
                onClick={_closeSignUp}
            >
                <img src={close} />
            </div>
            <div className="sign-up-container__body">
                <h3 className="sign-up-container_title">Sign Up</h3>
                <div className="sign-up-container__form-container">
                    <TextField
                        value={firstName}
                        setValue={(e: string) => _setvalue(setFirstName, e)}
                        label="First name"
                    />
                    <TextField
                        value={lastName}
                        setValue={(e: string) => _setvalue(setLastName, e)}
                        label="Last name"
                    />
                    <TextField
                        value={email}
                        setValue={(e: string) => _setvalue(setEmail, e)}
                        label="E-Mail"
                    />
                    <PasswordField
                        value={password}
                        setValue={(e: string) => _setvalue(setPassword, e)}
                        label="Password"
                    />
                    <PasswordField
                        value={confirmPassword}
                        setValue={(e: string) =>
                            _setvalue(setConfirmPassword, e)
                        }
                        label="Confirm Password"
                    />
                    {error && (
                        <p style={{ color: 'red', fontWeight: 'bold' }}>
                            {error}
                        </p>
                    )}
                </div>
                <div className="sign-up-container__bottom-container">
                    <Button variant="black" text="Next" onClick={_signUp} />
                </div>
            </div>
        </div>
    )
}

export default SignUp
