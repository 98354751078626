import { useNavigate } from 'react-router-dom'
import icon from '../../assets/images/svgs/loggin-button.svg'
import person from '../../assets/images/svgs/person.svg'
import { Button } from '../button'

import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import './styles.scss'

export const Header = () => {
    const dispatch = useAppDispatch()
    const { user, screen } = useAppSelector((state) => state.auth)
    const navigate = useNavigate()

    const _handlePress = () => {
        if (
            screen === 'Corporate Details' ||
            screen === 'Corporate Details Slider'
        ) {
            dispatch(changeScreen('Menu Screen'))
        }
        document.body.style.overflow = 'hidden'
        navigate('/menu')
    }

    return (
        <div className="header">
            <div className="header__header-container">
                <h3 className="header_title">Shop with Impact</h3>
                <div className="header__auth-container">
                    <Button
                        text={user ? null : 'Login'}
                        image={user ? user.profile_photo.url : person}
                        variant="transparent"
                        onClick={_handlePress}
                        icon={user ? icon : ''}
                    />
                </div>
            </div>
        </div>
    )
}
