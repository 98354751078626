import { useEffect, useState } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setMoreInfo } from '../../store/impact/impact-action'
import { _renderScreen } from '../../utils/renderScreen'
import './styles.scss'

export const Modal = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { screen, user } = useAppSelector((state) => state.auth)
    const [active, setActive] = useState(false)

    //for add class when init component
    useEffect(() => {
        setActive(true)
    }, [])

    const _closeModal = () => {
        if (screen === 'Corporate Details') {
            dispatch(setMoreInfo(false))
        }
        document.body.style.overflow = 'visible'
        if (user) {
            dispatch(changeScreen('Menu Screen'))
        } else {
            dispatch(changeScreen('Start Screen'))
        }
        setActive(false)
        navigate('/')
    }

    const clientId =
    '984576170603-35v0k2t6l5qj9lkfsrpr56eom3m82su5.apps.googleusercontent.com'

    return (
        <div className={`modal ${active && 'active'}`}>
            <div
                className="modal__modal-container_1"
                onClick={_closeModal}
            ></div>
            <GoogleOAuthProvider clientId={clientId}>
            <div className="modal__modal-container_2">
                {_renderScreen(screen)}
            </div>
            </GoogleOAuthProvider>
        </div>
    )
}
