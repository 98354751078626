import arrow_back from '../../assets/images/svgs/arrow-back.svg'
import { Button } from '../../components/button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import './styles.scss'

const FinishSignUp = () => {
    const dispatch = useAppDispatch()

    const _goBack = () => {
        dispatch(changeScreen('Sign Up'))
    }

    const _signUp = () => {
        dispatch(changeScreen('Sign Up Preview'))
    }
    return (
        <div className="finish-container">
            <div className="finish-container__close-wrapper" onClick={_goBack}>
                <img src={arrow_back} />
            </div>
            <div className="finish-container__body">
                <div className="finish-container__content">
                    <h3 className="finish-container_title">
                        Finish Signing Up
                    </h3>
                    <p className="finish-container_info">
                        By tapping Sign Up, you agree to our{' '}
                        <a
                            href="https://www.ample.earth/terms-of-use"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="finish-container_underline"
                        >
                            Terms.
                        </a>{' '}
                        Learn how we collect, use and share your data in our{' '}
                        <span>Data Policy</span> and how we use cookies and
                        similar technology in our <span>Cookie Policy</span>.
                    </p>
                    <div className="finish-container__button-wrapper">
                        <Button
                            variant="black-outlined"
                            onClick={_signUp}
                            text="Sign Up"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinishSignUp
