import React from 'react'
interface Props {
    text: string
    icon: string
    onClick: () => void
}
export const Menu_buttons: React.FC<Props> = ({ text, icon, onClick }) => {
    return (
        <div onClick={onClick}>
            <button className="menu__buttons">
                <div className="menu__buttons__icon">
                    <img src={icon} />
                </div>
                <div className="menu__buttons__text">{text}</div>
            </button>
        </div>
    )
}
