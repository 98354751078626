import { useEffect, useState } from 'react'

import arrowBack from '../../assets/images/svgs/arrow-black.svg'
import arrow from '../../assets/images/svgs/arrow-curved.svg'
import points from '../../assets/images/svgs/points.svg'
import { ImpactCard } from '../../components/impact-card'
import { ProjectCard } from '../../components/project-card'
import { getAllProjects } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setInProject } from '../../store/impact/impact-action'
import { Project, User } from '../../types/api'
import './styles.scss'

const MyImpact = () => {
    const access_token = useAppSelector((state) => state.auth.access_token)
    const user = useAppSelector((state) => state.auth.user) as User
    const { inProject } = useAppSelector((state) => state.impact)
    const [impacts, setImpacts] = useState<Array<Project>>([])
    const [isLoad, setIsLoad] = useState(false)
    const [activeTab, setActiveTab] = useState(
        inProject ? 'Projects' : 'Impact'
    )
    const dispatch = useAppDispatch()
    const _setActiveTab = (tab: string) => {
        setActiveTab(tab)
    }

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'))
    }
    const _getAllProjects = async () => {
        const data = await getAllProjects(access_token.access_token)
        setImpacts(data)
        setIsLoad(true)
    }

    useEffect(() => {
        _getAllProjects()
    }, [])

    const myImpacts = impacts.filter((impact) => impact.user_total_units_amount)

    const filterImpacts = (text: string) => {
        const filteredImpacts = (
            activeTab === 'Impact' ? myImpacts : impacts
        ).filter((el: Project) => el.type.name === text && el)
        if (filteredImpacts[0]) {
            return filteredImpacts
        } else {
            return false
        }
    }
    const _renderImpacts = () => {
        const planetImpacts = filterImpacts('Planet')
        const peopleImpacts = filterImpacts('People')
        const principlesImpacts = filterImpacts('Principles')

        return (
            <>
                {planetImpacts && (
                    <>
                        <h3 className="impact-container_project-title">
                            Planet
                        </h3>
                        <div className="impact-container__cards-wrapper">
                            {planetImpacts.map((item: Project) =>
                                activeTab === 'Impact' ? (
                                    <ImpactCard key={item.id} item={item} />
                                ) : (
                                    <ProjectCard key={item.id} item={item} />
                                )
                            )}
                        </div>
                    </>
                )}

                {peopleImpacts && (
                    <>
                        <h3 className="impact-container_project-title">
                            People
                        </h3>
                        <div className="impact-container__cards-wrapper">
                            {peopleImpacts.map((item: Project) =>
                                activeTab === 'Impact' ? (
                                    <ImpactCard key={item.id} item={item} />
                                ) : (
                                    <ProjectCard key={item.id} item={item} />
                                )
                            )}
                        </div>
                    </>
                )}
                {principlesImpacts && (
                    <>
                        <h3 className="impact-container_project-title">
                            Principles
                        </h3>
                        <div className="impact-container__cards-wrapper">
                            {principlesImpacts.map((item: Project) =>
                                activeTab === 'Impact' ? (
                                    <ImpactCard key={item.id} item={item} />
                                ) : (
                                    <ProjectCard key={item.id} item={item} />
                                )
                            )}
                        </div>
                    </>
                )}
            </>
        )
    }

    return (
        <div className="impact-container">
            <div className="impact-container__header">
                <div
                    className="impact-container__header-left"
                    onClick={_goBack}
                >
                    <img src={arrowBack} />
                </div>
                <div className="impact-container__header-right">
                    <img
                        src={user.profile_photo.url}
                        className="impact-container_profile-image"
                    />
                    <p className="impact-container_profile-title">
                        {user.first_name}
                    </p>
                    <div className="impact-container_profile-border"></div>
                    <p className="impact-container_points-text">
                        {user.loyalty_account.amount.amount}
                    </p>
                    <img src={points} className="impact-container_image" />
                </div>
            </div>
            <div className="impact-container__content">
                <div className="impact-container__switch-container">
                    <div className="impact-container__switch-wrapper">
                        <div
                            className={`impact-container__switcher ${
                                activeTab === 'Impact' && 'active-switcher'
                            }`}
                            onClick={() => {
                                _setActiveTab('Impact')
                                dispatch(setInProject(false))
                            }}
                        >
                            <p className="impact-container_switcher-text">
                                Impact
                            </p>
                        </div>
                        <div
                            className={`impact-container__switcher ${
                                activeTab === 'Projects' && 'active-switcher'
                            }`}
                            onClick={() => {
                                _setActiveTab('Projects')
                                dispatch(setInProject(true))
                            }}
                        >
                            <p className="impact-container_switcher-text">
                                Projects
                            </p>
                        </div>
                    </div>
                </div>
                <div className="impact-container__content-wrapper">
                    {_renderImpacts()}
                </div>
                {isLoad && myImpacts.length === 0 && activeTab === 'Impact' && (
                    <>
                        <img
                            alt="arrow"
                            src={arrow}
                            className="impact-container__no-projects-img"
                        />
                        <h3 className="impact-container__no-projects">
                            Support a project today and watch your impact grow
                            here
                        </h3>
                    </>
                )}
            </div>
        </div>
    )
}

export default MyImpact
