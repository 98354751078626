import SettingsHeader from '../../components/settings-header'
import './styles.scss'

const TermsOfUse = () => {
    return (
        <div className="terms-of-use__container">
            <SettingsHeader screen="Settings" text="TERMS OF USE" />
            <div className="terms-of-use__container__body">
                <div className="terms-of-use__container__first-text">
                    Hello, welcome to Ample`s Terms and Conditions of Use (these
                    &quot;Terms&quot;). This is a contract between you and
                    Proton Partners Ltd, trading as Ample, the owner and
                    operator of this Website. We want you to be aware of your
                    rights before using our services. Please read these Terms &
                    Conditions carefully, as they affect your legal rights. Your
                    agreement to comply with and be bound by these Terms and
                    Conditions is deemed to occur upon your first use of the
                    Website. If you do not agree to be bound by these Terms and
                    Conditions, you should stop using the Website immediately.
                    You must be at least 18 years of age to use this Website. If
                    you are under the age of 18, you should review these Terms
                    and Conditions with your parent or guardian before agreeing
                    to make sure that you or your parent or guardian understand
                    your and their legal obligations.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Content & Intellectual property
                    </p>
                    All Content included on the Website, unless uploaded by
                    Users, is the property of Ample, our affiliates or other
                    relevant third parties. In these Terms and Conditions,
                    Content means any text, graphics, images, audio, video,
                    software, data compilations, page layout, underlying code
                    and software and any other form of information capable of
                    being stored in a computer that appears on or forms part of
                    this Website, including any such content uploaded by Users.
                    By continuing to use the Website you acknowledge that such
                    Content is protected by copyright, trademarks, database
                    rights and other intellectual property rights. Nothing on
                    this site shall be construed as granting, by implication,
                    estoppel, or otherwise, any license or right to use any
                    trademark, logo or service mark displayed on the site
                    without the owner`s prior written permission. You must not
                    otherwise reproduce, modify, copy, distribute or use for
                    commercial purposes any Content without the written
                    permission of Ample. All right, title and interest in and to
                    Our Content remains with us at all times.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        We don`t allow certain content on Ample
                    </p>
                    We want our users to freely communicate on Ample, but we
                    have to impose restrictions on certain content that:
                    <ul>
                        <li>
                            contains language which could be deemed offensive;
                        </li>
                        <li>
                            is obscene, pornographic or otherwise may offend
                            human dignity;
                        </li>
                        <li>
                            is abusive, insulting or threatening, or which
                            promotes or encourages racism, sexism, hatred or
                            bigotry;
                        </li>
                        <li>
                            encourages any illegal activity including, without
                            limitation, terrorism, inciting racial hatred or the
                            submission of which in itself constitutes committing
                            a criminal offence;
                        </li>
                        <li>is defamatory or libellous;</li>
                        <li>
                            relates to commercial activities (including, without
                            limitation, sales, competitions and advertising,
                            links to other websites or premium line telephone
                            numbers);
                        </li>
                        <li>
                            involves the transmission of &quot;junk&quot; mail
                            or &quot;spam&quot;;
                        </li>
                        <li>
                            contains any spy ware, adware, viruses, corrupt
                            files, worm programmes or other malicious code
                            designed to interrupt, damage or limit the
                            functionality of or disrupt any software, hardware,
                            telecommunications, networks, servers or other
                            equipment, Trojan horse or any other material
                            designed to damage, interfere with, wrongly
                            intercept or expropriate any data or personal
                            information whether from XX or otherwise;
                        </li>
                        <li>
                            itself, or the posting of which, infringes any third
                            party`s rights (including, without limitation,
                            intellectual property rights and privacy rights);
                        </li>
                        <li>
                            shows another person which was created or
                            distributed without that person`s consent.
                        </li>
                    </ul>
                    <br />
                    We operates a zero-tolerance policy for this kind of
                    content.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Your Content
                    </p>
                    As Your Content is created by you, you are responsible and
                    liable for Your Content and will indemnify, defend, release,
                    and hold us harmless from any claims made in connection with
                    Your Content. We have the right to remove, edit, limit or
                    block access to any of Your Content at any time, and we have
                    no obligation to display or review Your Content.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Member Content
                    </p>
                    Other members of Ample will also share content via the
                    Website & App. Member Content belongs to the user who posted
                    the content. You do not have any rights in relation to other
                    users` Member Content. You can report any abuse or complain
                    about Member Content by contacting us, outlining the abuse
                    and/or complaint. Email us at hello@ample.earth
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Confidentiality and Data Protection
                    </p>
                    Ample will maintain confidentiality of your data, and will
                    make reasonable efforts to prevent any unauthorised use,
                    disclosure, copying, publication or dissemination of your
                    data, except and only to the extent necessary in accordance
                    with these Terms and Conditions. In these Terms and
                    Conditions, “Personal Data” means any information relating
                    to an identifiable person who can be directly or indirectly
                    identified in particular by reference to an identifier.
                    Wherever Ample determines the means and purposes of the
                    processing of Personal Data relating to you, Ample will be
                    the `data controller` (as such term is understood under
                    applicable data protection laws) of such Personal data, and
                    the terms of Ample`s Privacy Notice shall apply to such
                    processing. Ample will in accordance with its Privacy
                    Notice: a. comply at all times with applicable privacy and
                    data protection laws; b. take all reasonable steps to
                    protect the security and integrity of (i) information that
                    can be used to establish the identity of you, (ii) sensitive
                    information about you and (iii) your Personal Data; and c.
                    take reasonable steps to notify you if Ample becomes aware
                    of any disclosure of (i) your Personal Data or other data
                    that can be used to establish the identity of you, or (ii)
                    sensitive information about you that is associated with you.
                    <br />
                    By using our services, you grant to us and our Trusted
                    Partners a non-exclusive, royalty-free licence to use the
                    information in your Personal Account(s) for all purposes
                    connected with the Service or referred to in these Terms or
                    the Privacy Policy, with the right to use, modify, display,
                    and create new material using or incorporating such
                    information to provide the Service to you. By submitting
                    information, you agree (without the payment of any fees),
                    that our Trusted Partners and us may use the information for
                    the purposes set out above.
                    <br />
                    We do not check the accuracy of the Personal Accounts
                    information and personal information you provide to us and
                    we rely on you and your Personal Account providers to ensure
                    that the Personal Accounts information and personal
                    information you provide to us is up to date and accurate.
                    <br />
                    For our compliance purposes and in order to provide the
                    Service to you, you hereby authorise us to, directly or
                    through a third-party, obtain, verify, and record
                    information and documentation that helps us verify your
                    identity and Personal Account information. When you register
                    for the Service and from time to time thereafter, we may
                    require you to provide and/or confirm information and
                    documentation that will allow us to identify you.
                    <br />
                    You agree that we will be entitled to disclose your identity
                    and information relating to your Personal Account(s) to
                    third parties if we are required to do so by any applicable
                    law or court order. For more information about how Ample
                    collects, uses, and shares your personal data, please check
                    out our Privacy Policy.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Disclaimer / Liability
                    </p>
                    Your use of the service, including, without limitation, your
                    use of any content accessible through the website and your
                    interactions and dealing with any service users is at your
                    sole risk. The service and all content available on and
                    through the website or service are provided on an ‘as is’
                    and ‘as available’ basis. Ample and its suppliers and
                    licensors expressly disclaim all warranties of any kind,
                    whether express or implied, including but not limited to the
                    implied warranties of merchantability, fitness for
                    particular purpose, title, and non-infringement. Ample does
                    not warrant uninterrupted use or operation of the service or
                    your access to any content. No advice or information,
                    whether oral or written, obtained by you from the service
                    will create any warranty regarding Ample that is not
                    expressly stated in these terms. Some jurisdictions may
                    prohibit a disclaimer of warranties and you may have other
                    rights that vary from jurisdiction to jurisdiction.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Linking to our website
                    </p>
                    You may link to any page of our Website, provided that you
                    do so in a way that is legal and that does not damage or
                    take advantage of our reputation nor seeks to do so. You
                    must not suggest any form of association, approval or
                    endorsement on our part where none exists. You must not
                    remove, obscure or modify any advertisements, copyright
                    notice or other information on our Website. Our Website must
                    not be framed on any other website. The website in which you
                    are linking must comply in all respects with the content
                    standards set out in these Terms of Use and we reserve the
                    right to withdraw linking permission without notice.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">
                        Indemnification
                    </p>
                    You agree to protect and fully compensate our Trusted
                    Partners and us and our affiliates from any and all
                    third-party claims, liability, damages, expenses and costs
                    (including, but not limited to, reasonable solicitors’ fees)
                    caused by or arising from your use of the Service, your
                    violation of these Terms or your infringement, or
                    infringement by any other user of your account, of any
                    intellectual property or other right of anyone.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">General</p>
                    You may not transfer any of your rights under these Terms
                    and Conditions to any other person. We may transfer our
                    rights under these Terms and Conditions where we reasonably
                    believe your rights will not be affected. Ample reserves the
                    right at any time, or from time to time to update, revise,
                    supplement or otherwise modify these Terms and Conditions
                    and to impose new or additional terms. All updates,
                    revisions, modifications and new rules will be effective
                    immediately and incorporated into these Terms and
                    Conditions. Your continued use of Ample following any Change
                    constitutes your acceptance of the Change and you will be
                    legally bound by the new updated Terms. If you do not accept
                    any Changes to the Terms, you should stop using Ample
                    immediately.
                    <br />
                    These Terms and Conditions together with the Privacy Notice
                    and Cookies Policy contain the whole agreement between the
                    parties relating to its subject matter and supersede all
                    prior discussions, arrangements or agreements that might
                    have taken place in relation to the Terms and Conditions.
                </div>
                <div className="terms-of-use__container__first-text">
                    <p className="terms-of-use__container_title">Enquiries</p>
                    If you have an enquiry or complaint about the Service, or
                    simply wish to get in touch, you should contact our customer
                    services team via our email hello@ample.earth.
                </div>
            </div>
        </div>
    )
}

export default TermsOfUse
