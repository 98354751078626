import moment from 'moment'
import { useEffect, useState } from 'react'
import arrow_black from '../../assets/images/svgs/arrow-black.svg'
import info from '../../assets/images/svgs/info.svg'
import TransactionInfo from '../../components/transaction-info'
import { TransactionDateContainer } from '../../components/transactions-date-container'
import { getTransactions } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Transaction } from '../../types/api'
import './styles.scss'

const TransactionScreen = () => {
    const { access_token } = useAppSelector((state) => state.auth)
    const { transactions } = useAppSelector((state) => state.transactions)
    const points = useAppSelector(store => store.auth.user?.loyalty_account.detailed)
    const dispatch = useAppDispatch()
    const [transactionsDate, setTransactionsDate] = useState<any>([])
    const [showInfo, setShowInfo] = useState(false)
    useEffect(() => {
        const dates: any = new Set()
        transactions.map((el: Transaction) => {
            dates.add(moment(el.date).format('DD/MM/YYYY'))
        })
        setTransactionsDate([...dates])
    }, [transactions])

    useEffect(() => {
        getTransactions(access_token.access_token)
    }, [])

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'))
    }

    const _showInfoModal = () => {
        setShowInfo(true)
    }

    const _hideInfoModal = () => {
        setShowInfo(false)
    }
    return (
        <div className="transaction-container">
            <div className="transaction-container__header-container">
                <img
                    className="transaction-container__header-arrow"
                    src={arrow_black}
                    onClick={_goBack}
                />
                <h1 className="transaction-container_header-title">
                    My Transactions
                </h1>
                <div
                    className="transaction-container_header-info"
                    onClick={_showInfoModal}
                >
                    <img src={info} />
                </div>
            </div>
            <div className="transaction-balance">
                    <div className="transaction-balance__info">
                        <span className='transaction-balance__type'>Points Earned:</span>
                        <strong className='transaction-balance__amount'>{points?.points_earned}</strong>
                    </div>
                    <div className="transaction-balance__info">
                        <span className='transaction-balance__type'>Points Cleared:</span>
                        <strong className='transaction-balance__amount'>{points?.points_cleared}</strong>
                    </div>
                    <div className="transaction-balance__info">
                        <span className='transaction-balance__type'>Available Balance:</span>
                        <strong className='transaction-balance__amount'>{points?.available_balance}</strong>
                    </div>
                </div>
            <div className="transaction-container__content">
                {transactionsDate?.map((el: string, index: number) => (
                    <TransactionDateContainer
                        key={index}
                        date={el}
                        elements={transactions.filter(
                            (item: Transaction) =>
                                moment(item.date).format('DD/MM/YYYY') === el &&
                                item
                        )}
                    />
                ))}
            </div>
            {showInfo && <TransactionInfo hideInfo={_hideInfoModal} />}
        </div>
    )
}

export default TransactionScreen
