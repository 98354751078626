import arrow_back from '../../assets/images/svgs/arrow-back.svg'
import { Button } from '../../components/button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import './styles.scss'

const ProjectDetails = () => {
    const { activeProject } = useAppSelector((state) => state.impact)
    const dispatch = useAppDispatch()

    const _goBack = () => {
        dispatch(changeScreen('My Impact'))
    }
    const _sponsor = () => {
        dispatch(changeScreen('Project Sponsor'))
    }
    return (
        <div className="details-container">
            <div
                className="details-container__hero-wrapper"
                style={activeProject.background_image ? { backgroundImage: `url(${activeProject.background_image.url})` } : {
                    backgroundColor: '#004236'
                }}
            >
                <div
                    className="details-container__close-wrapper"
                    onClick={_goBack}
                >
                    <img src={arrow_back} />
                </div>
                <div className="details-container_title">
                    {activeProject.name}
                </div>
            </div>
            <div className="details-container__content">
                <div
                    className="details-container__content-wrapper"
                    dangerouslySetInnerHTML={{
                        __html: activeProject.description ? activeProject.description : 'Loading...',
                    }}
                ></div>
                <div className="details-container__button-wrapper">
                    <Button variant="black" text="Sponsor" onClick={_sponsor} />
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails
