import React from 'react'
import './styles.scss'

interface Props {
    text?: string | null
    icon?: string
    image?: string
    variant: string
    onClick: () => void
    disabled?: boolean
}

export const Button: React.FC<Props> = ({
    text,
    image,
    variant,
    onClick,
    icon,
    disabled = false,
}) => {
    return (
        <>
            <button
                className={`${variant} button ${disabled && 'disabled'}`}
                onClick={onClick}
            >
                <img className={`${variant}_icon`} src={icon} />
                <p className={`${variant}_title`}>{text}</p>
                <img className={`${variant}_image`} src={image} />
            </button>
        </>
    )
}
