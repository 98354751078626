import React from 'react'
import { Button } from '../button'
import './styles.scss'

interface Props {
    hideInfo: () => void
}

const Percentage: React.FC<Props> = ({ hideInfo }) => {
    return (
        <div className="percentage-info-container">
            <div className="percentage-info-container__modal">
                <h3 className="percentage-info-container_title">Percentage</h3>
                <p className="percentage-info-container_subtitle">
                    Every time you spend get a % back to do good
                </p>
                <br />
                <p className="percentage-info-container_desc">
                    For example, 5% means you will receive 5% of your
                    transaction back as points.
                </p>
                <p className="percentage-info-container_desc">
                    Use your points to leave a lighter footprint by supporting
                    eco-social impact projects.
                </p>

                <Button variant="black" text="OK" onClick={hideInfo} />
            </div>
        </div>
    )
}

export default Percentage
