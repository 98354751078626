import React from 'react'
import './styles.scss'
interface Props {
    text: string
    icon: string
    arrow?: string
    onClick: () => void
}
export const SettingsButton: React.FC<Props> = ({
    text,
    icon,
    arrow,
    onClick,
}) => {
    return (
        <div onClick={onClick}>
            <button className="button-container">
                <div className="button-container__icon-text">
                    <div
                        className={
                            text === 'Edit profile'
                                ? 'button-container__icon-edit'
                                : 'button-container__icon'
                        }
                    >
                        <img src={icon} />
                    </div>
                    <div className="button-container__text">{text}</div>
                </div>
                {arrow && (
                    <div className="button-container__arrow">
                        <img src={arrow} />
                    </div>
                )}
            </button>
        </div>
    )
}
