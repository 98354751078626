import { useState } from 'react'
import Slider from 'react-slick'
import { Button } from '../../components/button'
import { sliders } from '../../services/constants'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import './styles.scss'
const SignUpPreview = () => {
    const [dots, setDots] = useState(true)

    const dispatch = useAppDispatch()

    const settings = {
        dots: dots,
        infinite: false,
        arrows: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        afterChange: (currentSlide: number) => {
            if (currentSlide === sliders.length - 1) {
                setDots(false)
            } else {
                setDots(true)
            }
        },
    }

    const _next = () => {
        dispatch(changeScreen('Welcome Screen'))
    }
    return (
        <div className="preview-container">
            <div className="preview-container__content-wrapper">
                <Slider {...settings}>
                    {sliders.map((el: any, index: number) => (
                        <div key={el.text + index}>
                            <img src={el.image} />
                            <p className="slider-item-text">{el.text}</p>
                        </div>
                    ))}
                </Slider>
                <div className="preview-container__bottom-wrapper">
                    {!dots && (
                        <Button variant="black" text="Next" onClick={_next} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default SignUpPreview
